
import { EventBus } from "@/utils/event-bus"
import PopupCard from "./PopupCard"
import BulkPopupCard from "./BulkPopupCard"
import PopupCardCounter from "./PopupCardCounter"
import PopupMixin from "./popup-mixin"
import { mapMutations, mapGetters } from "vuex"
import * as uuid from "uuid/v1"
import _ from "lodash"

export default {
  name: "PopupActivityCard",
  props: {
    onlyAllowOnePopup: {
      type: Boolean,
      default: true,
    },
    showContact: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [PopupMixin],
  components: {
    PopupCard,
    BulkPopupCard,
    PopupCardCounter,
  },
  data() {
    return {
      itemsToShow: [],
      componentKey: 0,
      showPopupcards: true,
    }
  },
  beforeDestroy() {
    EventBus.$off("note")
    EventBus.$off("close_memo")
    EventBus.$off("note_bulk")
    EventBus.$off("lead_review_sms")
  },
  computed: {
    ...mapGetters({
      activeIndex: "activityPopup/getActivePopupIndex",
      numPopups: "activityPopup/getNumPopups",
      getActivityPopupsActions: "activityPopup/getActivityPopupsActions",
      activityPopups: "activityPopup/getActivityPopups",
      selectedAccount: "accounts/getSelectedAccount",
      contact: "leads/contact",
      user: "user/getUser",
    }),
  },
  watch: {

    activityPopups: {
      handler(newVal, oldVal) {
        // this.showPopupcards = false
        // setTimeout(() => {
        //   this.showPopupcards = true
        // })

        this.$forceUpdate()
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    // this.itemsToShow = _.cloneDeep(this.activityPopups)
    // Data is contact / type
    EventBus.$on("note", (data) => {
      let actions = this.getActivityPopupsActions(data.type)
      if (this.onlyAllowOnePopup && this.numPopups === 1) {
        this.$store.commit("activityPopup/removeActivityPopup", 0)
        this.$store.commit("activityPopup/setActivePopupIndex", 0)
      }
      EventBus.$emit("popover_clicked", null)
      setTimeout(async () => {
        this.forceRerender()
        await this.$store.dispatch("activityPopup/addActivityPopup", {
          contact: data.contact,
          activity: {
            event_action: actions.event_action,
            action: actions.event_action,
            card_title: actions.card_title,
            type: data.type,
            app_id: this.selectedAccount.client_code,
            title: this.createEventAction(data.type),
            category: "Insights Activity",
            note: "",
            inferred_user_id: data.contact.email.toLowerCase(),
            session_id: uuid(),
          },
        })
        // this.showPopupcards = false
        this.$store.commit("activityPopup/setActivePopupIndex", 0)

      }, 10)
    })
    EventBus.$on("note_bulk", (data) => {
      let actions = this.getActivityPopupsActions(data.type)
      if (this.onlyAllowOnePopup && this.numPopups === 1) {
        this.$store.commit("activityPopup/removeActivityPopup", 0)
        this.$store.commit("activityPopup/setActivePopupIndex", 0)
      }
      EventBus.$emit("popover_clicked", null)
      setTimeout(async () => {
        let activity = []
        for (let contact of data.contacts) {
          if (contact.email) {
            activity.push({
              event_action: actions.event_action,
              action: actions.event_action,
              card_title: actions.card_title,
              type: data.type,
              app_id: this.selectedAccount.client_code,
              title: this.createEventAction(data.type),
              category: "Insights Activity",
              note: "",
              inferred_user_id: contact.email.toLowerCase(),
              session_id: uuid(),
            })
          }
        }
        this.forceRerender()
        await this.$store.dispatch("activityPopup/addActivityPopupBulk", {
          contacts: data.contacts,
          activity: activity,
          activeEventTable: data.activeEventTable
        })
        // this.showPopupcards = false
        this.$store.commit("activityPopup/setActivePopupIndex", 0)

      }, 10)
    })
    // Data is contact / type
    EventBus.$on("lead_review_sms", (data) => {
      let actions = this.getActivityPopupsActions(data.type)
      if (this.onlyAllowOnePopup && this.numPopups === 1) {
        this.$store.commit("activityPopup/removeActivityPopup", 0)
        this.$store.commit("activityPopup/setActivePopupIndex", 0)
      }
      setTimeout(async () => {
        this.forceRerender()
        await this.$store.dispatch("activityPopup/addActivityPopup", {
          contact: data.contact,
          activity: {
            event_action: actions.event_action,
            action: actions.event_action,
            card_title: actions.card_title,
            type: data.type,
            app_id: this.selectedAccount.client_code,
            title: `<span class="event-color">${ this.user.name }</span> sent a SMS for a Google Review`,
            category: "Insights Activity",
            note: "",
            inferred_user_id: data.contact.email.toLowerCase(),
            session_id: uuid(),
          },
          index: data.index
        })
        this.$store.commit("activityPopup/setActivePopupIndex", 0)
        // this.showPopupcards = false

      }, 10)
    })
    EventBus.$on("close_memo", (index) => {
      this.$store.commit("activityPopup/removeActivityPopup", index)
      this.$store.commit("activityPopup/setActivePopupIndex", 0)
      setTimeout(() => {
        setTimeout(async () => {
          this.forceRerender()
        }, 50)
      }, 30)
    })
  },
  methods: {
    getComponentKey(index) {
      return this.componentKey + index
    },
    forceRerender() {
      this.componentKey += 1
    },
    createEventAction(action) {
      if (action == "log_note") {
        return `<span class="event-color">${ this.user.name }</span> left a note`
      } else if (action == "log_call") {
        return `<span class="event-color">${ this.user.name }</span> logged a phone call`
      } else if (action == "log_email") {
        return `<span class="event-color">${ this.user.name }</span> logged an email`
      } else if (action == "move_in") {
        return `<span class="event-color">${ this.user.name }</span> logged a move in`
      } else if (action == "move_out") {
        return `<span class="event-color">${ this.user.name }</span> logged a move out`
      }
    },
  },
}
