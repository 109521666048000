/**
 * Convert string to title case string.
 *
 * @param { string } value String passed into filter
 *
 * @example
 *      {{ "heLLo wORLD" | titlecase }}
 *
 *      Output: "Hello World"
 */
export default function titlecase(value) {
  return value.replace(/\w\S*/g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}
