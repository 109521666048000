
import * as _ from "lodash"
import { EventBus } from "@/utils/event-bus"

export default {
  name: "PopupHeader",
  props: {
    title: {
      type: String,
      required: true,
    },
    isCollapsed: {
      type: Boolean,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    contact: {
      type: Object,
    },
  },
  data() {
    return {
      collapsed: false,
    }
  },
  computed: {
    contactName() {
      let name = ""
      if (this.contact.first_name) {
        name += this.contact.first_name + " "
      }
      if (this.contact.last_name) {
        name += this.contact.last_name + " "
      }
    }
  },
  created() {
    this.collapsed = _.clone(this.isCollapsed)
  },
  watch: {
    isCollapsed() {
      this.collapsed = _.clone(this.isCollapsed)
    },
  },
  methods: {
    setCollapse() {
      this.collapsed = !this.collapsed
      this.$emit("collapsed", this.collapsed)
    },
    close() {
      EventBus.$emit("close_memo", this.index)
    },
  },
}
