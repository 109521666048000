import { render, staticRenderFns } from "./PopupActivityCard.vue?vue&type=template&id=5e49cd23&lang=pug"
import script from "./PopupActivityCard.vue?vue&type=script&lang=js"
export * from "./PopupActivityCard.vue?vue&type=script&lang=js"
import style0 from "./PopupActivityCard.vue?vue&type=style&index=0&id=5e49cd23&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PopupCard: require('/dashboard/cards/popup-activity-card/PopupCard.vue').default,BulkPopupCard: require('/dashboard/cards/popup-activity-card/BulkPopupCard.vue').default,PopupCardCounter: require('/dashboard/cards/popup-activity-card/PopupCardCounter.vue').default})
