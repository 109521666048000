
export default {
  name: 'PugBadge',
  props: {
    /**
    * The color of the badge. These are not Bootstrap variants, they match Figma.
    */
    variant: {
      type: [String, Number]
    },
    /**
    * This can be used instead of the slot
    */
    label: {
      type: String
    },
    /**
    * Add a class to the badge.
    */
    className: {
      type: String
    },
    /**
    * The size of the Badge: sm, md or lg
    */
    size: {
      type: String,
      default: 'md'
    },
  },
  data() {
    return {
      colors: [
        { bg: "rgba(134, 71, 179, 0.1)", main: "#8647B3" },
        { bg: "rgba(247, 131, 0, 0.1)", main: "#F78300" },
        { bg: "rgba(0, 133, 227, 0.1)", main: "#0085E3" },
        { bg: "rgba(255, 87, 77, 0.1)", main: "#FF574D" },
        { bg: "rgba(53, 169, 111, 0.1)", main: "#35A96F" },
        { bg: "rgba(15, 150, 150, 0.1)", main: "#0F9696" },
        { bg: "rgba(50, 76, 221, 0.1)", main: "#324CDD" },
        { bg: "rgba(16, 180, 209, 0.1)", main: "#10B4D1" },
        { bg: "rgba(217, 72, 171, 0.1)", main: "#D948AB" },
      ],
      colorMap: {
        primary: { bg: "rgba(134, 71, 179, 0.1)", main: "#8647B3" },
        orange: { bg: "rgba(247, 131, 0, 0.1)", main: "#F78300" },
        lightBlue: { bg: "rgba(0, 133, 227, 0.1)", main: "#0085E3" },
        red: { bg: "rgba(255, 87, 77, 0.1)", main: "#FF574D" },
        danger: { bg: "rgba(255, 87, 77, 0.1)", main: "#FF574D" },
        green: { bg: "rgba(53, 169, 111, 0.1)", main: "#35A96F" },
        success: { bg: "rgba(53, 169, 111, 0.1)", main: "#35A96F" },
        teal: { bg: "rgba(15, 150, 150, 0.1)", main: "#0F9696" },
        blue: { bg: "rgba(50, 76, 221, 0.1)", main: "#324CDD" },
        fuscia: { bg: "rgba(16, 180, 209, 0.1)", main: "#10B4D1" },
        pink: { bg: "rgba(217, 72, 171, 0.1)", main: "#D948AB" },
        secondary: { bg: "#f2f4f7", main: "#21252" },
        tommy: { bg: "rgba(145, 122, 237, 0.1)", main: "#917AED" },
        wing: { bg: "rgba(0, 124, 189, 0.1)", main: "#007CBD" },
        matthew: { bg: "rgba(247, 131, 0, 0.1)", main: "#F78300" },
        wayne: { bg: "rgba(53, 169, 111, 0.1)", main: "#35A96F" },
        jon: { bg: "rgba(69, 71, 111, 0.1)", main: "#45476F" },
      }
    }
  },
  methods: {
    getColor() {
      if (this.colorMap[this.variant]) {
        return this.colorMap[this.variant]
      }
      else {
        return this.colorMap['primary']
      }
    }
  }
}
