/**
 * Truncate string by amount of characters passed in. If
 * characters is null, default truncate is by one character.
 *
 * @param { string } value String passed into filter
 * @param { number } characters Number of characters to slice
 * @param { boolean } elipses Whether to add or remove ellipses to truncated string
 */
export default function truncate(value, characters = 1, elipses = true) {
  if (!value) {
    return ''
  }

  if (value.length <= characters) {
    return value
  }

  value = value.toString()

  return `${value.slice(0, characters)}${elipses ? '...' : ''}`
}
