
import PugImg from "@/dashboard/pug-img"

export default {
  name: 'EmailReportChart',
  components: {
    PugImg
  },
  props: {
    chart: {
      type: Object
    },
    hideEdit: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      isEdit: false
    }
  },
  watch: {
    isEdit() {
      this.$nextTick(() => {
        if (this.isEdit) {
          this.$refs[this.chart.id].focus()
        }
      })
    }
  },
  methods: {
    updateChart() {
      if (this.isEdit) {
        this.isEdit = false
        this.$emit('updateChart', this.chart)
      }
    }
  }
}
