// require("dotenv").config()

var conf
let stage = process.env.stage || process.env.stage || "dev"
export const isProd = stage === "production"

if (isProd) {
  conf = {
    api_url: process.env.api_url || "https://api.storagepug.app",
    api_version: process.env.api_version || "v1",
  }
} else {
  conf = {
    api_url: process.env.api_url || "https://api.storpug.com",
    api_version: process.env.api_version || "v1",
    web_url: process.env.web_url || null,
  }
}
conf["stage"] = process.env.stage || process.env.stage || "dev"
conf["google"] = process.env.VUE_APP_GOOGLE_PLACES_KEY || process.env.google
conf["code_version"] = process.env.codeVersion
// console.log(conf)
export const config = conf
