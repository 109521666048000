/**
 * Convert number input into currency string.
 *
 * @param { number } value Number value passed into filter
 * @param { number } digits Number of decimal places
 * @param { string } style NumberFormatPartType ("currency" | "decimal" | "fraction" | "group" | "infinity" | "integer" | "literal" | "minusSign" | "nan" | "plusSign" | "percentSign")
 * @param { string } currency Type of currency (`USD | Euro`)
 *
 * @example
 *      {{ 10 | currency }}
 *      Output: `$10.00`
 *
 *      {{ 10 | currency(1) }}
 *      Output: `$10.0`
 *
 */
export default function currency(value, digits, style, currency) {
  if (typeof value !== 'number') {
    return value
  }
  const formatter = new Intl.NumberFormat('en-US', {
    style: style || 'currency',
    currency: currency || 'USD',
    minimumFractionDigits: digits || 2
  })

  return formatter.format(value)
}
