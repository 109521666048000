
import FeatherIcon from "@/helpers/FeatherIcon"
import PopupHeader from "./PopupHeader"
import PugImg from "@/dashboard/pug-img"
import { mapMutations, mapGetters } from "vuex"
import FroalaEditor from "froala-editor"
import * as _ from "lodash"
import { EventBus } from "@/utils/event-bus"
import LeadReviewSms from "@/dashboard/modals/lead-review-sms"

import * as uuid from "uuid/v1"
import { formatToTimeZone } from "date-fns-timezone"
import DynamoActivity from "@/mixins/dynamo-activity"

/**
 * I did not make the bulk Popup work for SMS, you've been warned. We will have to do that soon enough
 *
*/
export default {
  name: "BulkPopupCard",
  components: {
    FeatherIcon,
    PopupHeader,
    PugImg,
    LeadReviewSms,
  },
  mixins: [DynamoActivity],
  props: {
    item: {
      type: Object,
    },
    allActivity: {
      type: Array,
    },
    index: {
      type: Number,
    },
    activeEventTable: {
      type: String,
    },
    contacts: {
      type: Array,
    },
    showContact: {
      type: Boolean,
      default: false,
    },
    startingActiveIndex: {
      type: Number,
    },
    // To remove the tenant from the next actions card
    itemIndex: {
      type: Number,
    },
  },
  data() {
    return {
      isCollapsed: false,
      isNew: true,
      origItem: null,
      newItem: null,
      isLoading: false,
      note: "",
      editor: null,
      id: null,
      popoverId: null,
      froalaConfig: {
        charCounterCount: false,
        wordPasteModal: false,
        quickInsertTags: [""],
        key: this.$config.froala_key,
        toolbarButtons: {
          moreText: {
            buttons: ["bold", "italic", "underline", "insertLink", "formatOL", "formatUL", "alignLeft", "alignCenter", "alignRight"],
            align: "left",
            buttonsVisible: 12,
          },
          // moreMisc: {
          //   buttons: ["alignLeft", "alignCenter", "alignRight", "alignJustify"],
          //   buttonsVisible: 0,
          //   align: "right",
          // },
        },
        heightMin: 180,
        toolbarButtonsXS: [["bold", "italic", "underline"]],
        placeholderText: "Add a note",
        attribution: false,
        zIndex: 1050,
      },
    }
  },
  created() {
    this.id = `froala-editor-${ uuid() }`
    this.popoverId = `popover-${ uuid() }`
    this.origItem = _.cloneDeep(this.item)
    this.newItem = _.cloneDeep(this.item)
    EventBus.$on("close_all_activity_popups", () => {
      // console.log("🚀 ~ file: PopupCard.vue ~ line 127 ~ EventBus.$on ~ close_all_activity_popups")
      this.isCollapsed = true

      //     setTimeout(() => {
      //   if (this.index === this.startingActiveIndex) {
      //     this.isCollapsed = false
      //   }
      //   else {
      //     this.isCollapsed = true
      //   }
      // }, 250)

    })
  },
  mounted() {
    if (this.item && this.item.type !== "log_review_sms_sent") {
      var vm = this
      this.froalaConfig = {
        ...this.froalaConfig,
        ...{
          events: {
            contentChanged: function () {
              // Do something here.
              // this is the editor instance.
              vm.note = _.cloneDeep(vm.editor.html.get())
              // vm.$store.commit("activityPopup/updateActivityPopup", { index: vm.index, data: _.cloneDeep(vm.newItem) })
            },
          },
        },
      }
      this.setEditor()
    }
    // if (this.index === this.startingActiveIndex) {
    //   this.isCollapsed = false
    // }
    // this.$nextTick(() => {

    //   if (this.index === this.startingActiveIndex) {
    //     console.log(this.index, this.startingActiveIndex)
    //     this.isCollapsed = false
    //   }
    // })
  },
  beforeDestroy() {
    EventBus.$off("close_all_activity_popups")
  },
  computed: {
    ...mapGetters({
      activeIndex: "activityPopup/getActivePopupIndex",
      activityPopups: "activityPopup/getActivityPopups",
    }),
  },
  watch: {
    item: {
      handler(newVal, oldVal) {
        this.isCollapsed = true
        if (this.item.type !== "log_review_sms_sent") {
          var vm = this
          this.froalaConfig = {
            ...this.froalaConfig,
            ...{
              events: {
                contentChanged: function () {
                  // Do something here.
                  // this is the editor instance.
                  vm.note = _.cloneDeep(vm.editor.html.get())
                  // vm.$store.commit("activityPopup/updateActivityPopup", { index: vm.index, data: _.cloneDeep(vm.newItem) })
                },
              },
            },
          }
          this.setEditor()
        }
        this.origItem = _.cloneDeep(this.item)
        this.newItem = _.cloneDeep(this.item)
        this.setOpenOrClosed()
      },
      deep: true,
      immediate: true,
    },
    activeIndex: {
      handler(newVal, oldVal) {
        this.$forceUpdate()
        // console.log("🚀 ~ file: PopupCard.vue ~ line 165 ~ handler ~ newVal, oldVal", `${ this.contact.first_name ? this.contact.first_name : '' } ${ this.contact.last_name ? this.contact.last_name : '' }`, newVal, oldVal, this.activeIndex, this.index)
        if (this.index !== this.activeIndex) {
          this.isCollapsed = true
        }
        else if (this.index === this.activeIndex) {
          this.isCollapsed = false
        }
        this.$forceUpdate()
        this.setOpenOrClosed()
      },
      deep: true,
      immediate: true,
    },
    isCollapsed() {
      if (this.isCollapsed === false && !this.item.type !== "log_review_sms_sent") {
        this.setEditor()
      }
    },
  },
  methods: {
    toggleCollapsed() {
      if (this.isCollapsed) {
        this.isCollapsed = false
      }
    },
    setEditor() {
      setTimeout(() => {
        this.editor = new FroalaEditor(`div#${ this.id }`, this.froalaConfig)
        setTimeout(() => {
          try {
            this.editor.html.set(this.newItem.note)
          } catch (e) {

          }
        }, 20)
      })
    },
    setCollapsed(e) {
      this.isCollapsed = e
      if (this.isCollapsed === false) {
        this.$store.commit("activityPopup/setActivePopupIndex", this.index)
      } else {
        this.$store.commit("activityPopup/setActivePopupIndex", -1)
      }
    },
    async saveNote() {
      this.isLoading = true
      for (let item of this.allActivity) {
        let eventToSave = _.cloneDeep(item)
        try {
          await this.saveNoteAction(eventToSave)
        } catch (e) {

        }
      }
      this.$store.commit("activityPopup/removeActivityPopupAndClearCacheBulk", this.index)
      this.isLoading = false
      setTimeout(() => {
        this.toastSuccess('Bulk update successful')
        EventBus.$emit("refresh_table", this.activeEventTable)
      }, 500)
    },
    async saveNoteAction(eventToSave) {

      var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
      eventToSave.date_tz = formatToTimeZone(new Date(), "YYYY-MM-DD", { timeZone })
      eventToSave.geo_timezone = timeZone
      eventToSave.note = this.note
      eventToSave.num = 1
      eventToSave.derived_tstamp = formatToTimeZone(new Date(), "MM/DD/YYYY h:mm:ss.SSS A", { timeZone })
      eventToSave["sort_key"] = `${ eventToSave["inferred_user_id"].toLowerCase() }#${ eventToSave["date_tz"] }#${ new Date().getTime() }#${ eventToSave["session_id"] }`
      eventToSave["sort_key_event"] = `${ eventToSave["inferred_user_id"].toLowerCase() }#${ this.getSortKey(eventToSave.type) }#${ eventToSave["date_tz"] }#${ new Date().getTime() }#${ eventToSave["session_id"] }`
      eventToSave["sort_key_event_table"] = `${ eventToSave.type }#${ eventToSave.category ? eventToSave.category.replace(/\s+/g, '-').toLowerCase() : eventToSave.category.replace(/\s+/g, '-').toLowerCase() }#${ eventToSave["date_tz"] }#${ eventToSave["inferred_user_id"].toLowerCase() }#${ new Date().getTime() }#${ eventToSave["session_id"] }`
      try {
        await this.saveActivityToDynamo(eventToSave)
        // EventBus.$emit("add_event_to_activity", eventToSave)
      } catch (e) {
        this.$rollbar.error("Error adding dynamo event", e, { event: eventToSave })
      }
      return true
    },
    close() {
      EventBus.$emit("close_memo", this.index)
    },
    setOpenOrClosed() {
      if (this.index !== this.activeIndex) {
        this.isCollapsed = true
      }
      else if (this.index === this.activeIndex) {
        this.isCollapsed = false
      }
    },
    getSortKey(type) {
      if (type === "log_email" || type === "log_call" || type === "log_note" || type === "log_review_sms_sent") {
        return "log"
      } else {
        return type
      }
    },
    getRight() {
      let right = 15
      if (this.isCollapsed === false) {
        if (this.index == 0) {
          return right
        }
        right = this.index * 250 + right
        return right
      } else if (this.activeIndex === -1) {
        right = this.index * 250 + right
        return right
      } else {
        if (this.index > this.activeIndex) {
          right = right + 260
        }
        right = this.index * 250 + right
        return right
      }
    },
    getTippyContent() {
      let content = "<div style='text-align: left;background: #fff;z-index: 99999999;padding: 5px;'>"
      let count = 0
      for (let contact of this.contacts) {
        content = content + `${ contact && contact.first_name ? contact.first_name : "" } ${ contact && contact.last_name ? contact.last_name : "" }<br>`
        count++
        if (count >= 15) {
          break
        }
      }
      if (this.contacts.length > count) {
        content = content + `<br>+ ${ this.contacts.length - count } others<br>`
      }
      content += "</div>"
      return content
    }
  },
}
