/**
 * Convert number to a percentage.
 *
 * @param { string } value String passed into filter
 *
 * @example
 *      {{ 7.53 | percentage(2) }}
 *
 *      Output: "7.53%"
 */
export default function percentage(value, decimals) {
  if (!value) {
    value = 0
  }
  if (!decimals) {
    decimals = 0
  }

  value = value * 100
  return Math.round(value * 10 ** decimals) / 10 ** decimals + '%'
}
