export function parseOptions(parent, options) {
  for (const item in options) {
    if (typeof options[item] !== 'object') {
      parent[item] = options[item]
    } else {
      parseOptions(parent[item], options[item])
    }
  }
}

export const Charts = {
  mode: 'light', // (themeMode) ? themeMode : 'light';
  fonts: {
    base: 'Open Sans'
  },
  colors: {
    gray: {
      100: '#f6f9fc',
      200: '#e9ecef',
      300: '#dee2e6',
      400: '#ced4da',
      500: '#adb5bd',
      600: '#8898aa',
      700: '#525f7f',
      800: '#32325d',
      900: '#212529'
    },
    theme: {
      default: '#5868db',
      primary: '#8647b3',
      secondary: '#ffbd57',
      info: '#aa7ccb',
      success: '#2dce89',
      danger: '#2dce89',
      warning: '#aa7ccb'
    },
    black: '#12263F',
    white: '#FFFFFF',
    transparent: 'transparent'
  }
}

export const MockDataList = [
  {
    labels: ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
      {
        label: 'Performance',
        data: [1, 55, 28, 40, 56, 60],
        pointRadius: 5,
        fill: false
      },
      {
        label: 'Location 2',
        data: [5, 56, 60, 55, 28, 40],
        pointRadius: 5,
        fill: false
      }
    ]
  },
  {
    labels: ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
      {
        label: 'Performance',
        data: [15, 55, 28, 40, 56, 60],
        fill: false
      }
    ]
  },
  {
    labels: ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
      {
        label: 'Location 2',
        data: [15, 56, 60, 55, 28, 40],
        borderColor: Charts.colors.theme.info,
        backgroundColor: Charts.colors.theme.info,
        fill: false
      }
    ]
  }
]

export const MockBarDataList = [
  {
    labels: ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
      {
        label: 'Performance',
        data: [5, 55, 28, 40, 56, 60],
        backgroundColor: Charts.colors.theme.primary,
        maxBarThickness: 20
      },
      {
        label: 'Location 2',
        data: [7, 56, 60, 55, 28, 40],
        backgroundColor: Charts.colors.theme.success,
        barThickness: 'flex',
        maxBarThickness: 10
      }
    ]
  },
  {
    labels: ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
      {
        label: 'Performance',
        data: [15, 55, 28, 40, 56, 60],
        backgroundColor: Charts.colors.theme.primary,
        barThickness: 'flex',
        maxBarThickness: 20
      }
    ]
  },
  {
    labels: ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
      {
        label: 'Location 2',
        data: [15, 56, 60, 55, 28, 40],
        backgroundColor: Charts.colors.theme.success,
        barThickness: 'flex',
        maxBarThickness: 20
      }
    ]
  }
]

function chartOptions(Chart) {
  const { colors, mode, fonts } = Charts
  // Options
  const options = {
    defaults: {
      global: {
        responsive: true,
        maintainAspectRatio: false,
        defaultColor: mode == 'dark' ? colors.gray[700] : colors.gray[600],
        defaultFontColor: mode == 'dark' ? colors.gray[700] : colors.gray[600],
        defaultFontFamily: fonts.base,
        defaultFontSize: 13,
        layout: {
          padding: 0
        },
        legend: {
          display: false,
          position: 'bottom',
          labels: {
            usePointStyle: true,
            padding: 16
          }
        },
        elements: {
          point: {
            radius: 5
            // backgroundColor: colors.theme.primary
          },
          line: {
            tension: 0.2,
            borderWidth: 4,
            // borderColor: colors.theme.primary,
            backgroundColor: colors.transparent,
            borderCapStyle: 'butt'
          },
          rectangle: {
            backgroundColor: colors.theme.warning
          },
          arc: {
            backgroundColor: colors.theme.primary,
            borderColor: mode == 'dark' ? colors.gray[800] : colors.white,
            borderWidth: 4
          }
        },
        tooltips: {
          enabled: true,
          mode: 'index',
          intersect: false
        }
      },
      doughnut: {
        cutoutPercentage: 83,
        legendCallback(chart) {
          const data = chart.data
          let content = ''

          data.labels.forEach(function (label, index) {
            const bgColor = data.datasets[0].backgroundColor[index]

            content += '<span class="chart-legend-item">'
            content += '<i class="chart-legend-indicator" style="background-color: ' + bgColor + '"></i>'
            content += label
            content += '</span>'
          })

          return content
        }
      }
    }
  }

  // yAxes
  // Horizontal lines
  Chart.scaleService.updateScaleDefaults('linear', {
    gridLines: {
      display: true,
      borderDash: [5],
      borderDashOffset: [0],
      color: mode == 'dark' ? colors.gray[900] : colors.gray[400],
      drawBorder: false,
      drawTicks: true, // false
      lineWidth: 2,
      zeroLineWidth: 2,
      zeroLineColor: mode == 'dark' ? colors.gray[900] : colors.gray[400],
      zeroLineBorderDash: [5],
      zeroLineBorderDashOffset: [0]
    },
    ticks: {
      beginAtZero: true,
      padding: 10,
      callback(value) {
        if (!(value % 10)) {
          return value
        }
      }
    }
  })

  // xAxes
  // Vertical lines
  Chart.scaleService.updateScaleDefaults('category', {
    gridLines: {
      drawBorder: false,
      drawOnChartArea: false,
      drawTicks: false
    },
    ticks: {
      padding: 20
    }
  })

  return options
}

export function initGlobalOptions(Chart) {
  parseOptions(Chart, chartOptions(Chart))
}

export const basicOptions = {
  maintainAspectRatio: false,
  legend: {
    display: false
  },
  responsive: true
}

export const blueChartOptions = {
  scales: {
    yAxes: [
      {
        gridLines: {
          color: Charts.colors.gray[700],
          zeroLineColor: Charts.colors.gray[700]
        },
        ticks: {
          beginAtZero: true
        }
      }
    ]
  }
}

export const lineChartOptionsBlue = {
  ...basicOptions,
  tooltips: {
    pointHoverRadius: 5,
    bodySpacing: 4,
    xPadding: 12,
    mode: 'nearest',
    intersect: 0,
    position: 'nearest'
  },
  elements: {
    point: {
      backgroundColor: Charts.colors.theme.primary
    }
  },
  maintainAspectRatio: false,
  responsive: true,
  scales: {
    yAxes: [
      {
        barPercentage: 1.6,
        ticks: {
          suggestedMin: 60,
          padding: 20,
          fontColor: '#9e9e9e'
        }
      }
    ]
  }
}

export const barChartOptionsGradient = {
  ...basicOptions,
  tooltips: {
    bodySpacing: 4,
    xPadding: 10,
    yPadding: 10,
    mode: 'nearest',
    intersect: false,
    position: 'nearest'
  },
  width: '100%',
  responsive: true,
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          padding: 1,
          callback(value) {
            if (!(value % 2)) {
              return value
            }
          }
        }
      }
    ]
  }
}

export const pieChartOptions = {
  ...basicOptions,
  tooltips: {
    backgroundColor: '#f5f5f5',
    titleFontColor: '#333',
    bodyFontColor: '#666',
    bodySpacing: 4,
    xPadding: 12,
    mode: 'nearest',
    position: 'nearest'
  },

  scales: {
    yAxes: [
      {
        display: 0,
        ticks: {
          display: true
        },
        gridLines: {
          drawBorder: true,
          zeroLineColor: 'transparent',
          color: 'rgba(255,255,255,0.05)'
        }
      }
    ],

    xAxes: [
      {
        display: 0,
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: 'rgba(255,255,255,0.1)',
          zeroLineColor: 'transparent'
        },
        ticks: {
          display: false
        }
      }
    ]
  }
}

export const purpleChartOptions = {
  ...basicOptions,
  tooltips: {
    backgroundColor: '#f5f5f5',
    titleFontColor: '#333',
    bodyFontColor: '#666',
    bodySpacing: 4,
    xPadding: 12,
    mode: 'nearest',
    intersect: 0,
    position: 'nearest'
  },
  scales: {
    yAxes: [
      {
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: 'rgba(29,140,248,0.0)',
          zeroLineColor: 'transparent'
        },
        ticks: {
          suggestedMin: 60,
          suggestedMax: 125,
          padding: 20,
          fontColor: '#9a9a9a'
        }
      }
    ],

    xAxes: [
      {
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: 'rgba(225,78,202,0.1)',
          zeroLineColor: 'transparent'
        },
        ticks: {
          padding: 20,
          fontColor: '#9a9a9a'
        }
      }
    ]
  }
}

export const orangeChartOptions = {
  ...basicOptions,
  tooltips: {
    backgroundColor: '#f5f5f5',
    titleFontColor: '#333',
    bodyFontColor: '#666',
    bodySpacing: 4,
    xPadding: 12,
    mode: 'nearest',
    intersect: 0,
    position: 'nearest'
  },
  scales: {
    yAxes: [
      {
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: 'rgba(29,140,248,0.0)',
          zeroLineColor: 'transparent'
        },
        ticks: {
          suggestedMin: 50,
          suggestedMax: 110,
          padding: 20,
          fontColor: '#ff8a76'
        }
      }
    ],

    xAxes: [
      {
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: 'rgba(220,53,69,0.1)',
          zeroLineColor: 'transparent'
        },
        ticks: {
          padding: 20,
          fontColor: '#ff8a76'
        }
      }
    ]
  }
}
export const greenChartOptions = {
  ...basicOptions,
  tooltips: {
    backgroundColor: '#f5f5f5',
    titleFontColor: '#333',
    bodyFontColor: '#666',
    bodySpacing: 4,
    xPadding: 12,
    mode: 'nearest',
    intersect: 0,
    position: 'nearest'
  },
  scales: {
    yAxes: [
      {
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: 'rgba(29,140,248,0.0)',
          zeroLineColor: 'transparent'
        },
        ticks: {
          suggestedMin: 50,
          suggestedMax: 125,
          padding: 20,
          fontColor: '#9e9e9e'
        }
      }
    ],

    xAxes: [
      {
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: 'rgba(0,242,195,0.1)',
          zeroLineColor: 'transparent'
        },
        ticks: {
          padding: 20,
          fontColor: '#9e9e9e'
        }
      }
    ]
  }
}

export const barChartOptions = {
  ...basicOptions,
  tooltips: {
    backgroundColor: '#f5f5f5',
    titleFontColor: '#333',
    bodyFontColor: '#666',
    bodySpacing: 4,
    xPadding: 12,
    mode: 'nearest',
    intersect: 0,
    position: 'nearest'
  },
  scales: {
    yAxes: [
      {
        gridLines: {
          drawBorder: true,
          color: 'rgba(29,140,248,0.1)',
          zeroLineColor: 'transparent'
        },
        ticks: {
          suggestedMin: 1,
          suggestedMax: 120,
          padding: 20,
          fontColor: '#9e9e9e'
        }
      }
    ],
    xAxes: [
      {
        gridLines: {
          drawBorder: false,
          color: 'rgba(29,140,248,0.1)',
          zeroLineColor: 'transparent'
        },
        ticks: {
          padding: 20,
          fontColor: '#9e9e9e'
        }
      }
    ]
  }
}
