import { mapMutations, mapGetters } from "vuex"
import _ from "lodash"


// < 948 ---- 1 popup
// 949 - 1199 ---- 2 popups
// 1200+ ---- 3 popups
export default {
  data() {
    return {
      totalPopups: 0,
      popupsVisible: 0,
      popupsHidden: 0,
      showPopupCounter: 0,
      onePopup: 948,
      twoPopups: 1199,
      threePopups: 1200,
      currentWidth: 0,
      currentBreakpoint: null,
    }
  },
  computed: {
    ...mapGetters({
      activityPopups: "activityPopup/getActivityPopups",
    }),
  },
  mounted() {
    // this.$nextTick(() => {
    // // Event listener to update breakpoint sizes
    //   window.addEventListener("resize", this.handleResize)
    //   this.handleResize() 
    // })
    if (process.client) {
      // Event listener to update breakpoint sizes
      window.addEventListener("resize", this.handleResize)
      this.handleResize() 
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize)
  },
  watch: {
    activityPopups: {
      handler(newVal, oldVal) {
       this.handleResize()
      },
      deep: true,
    },
  },
  methods: {
    handleResize() {
      const clientWidth = window.innerWidth
      this.currentWidth = clientWidth

      // Set the current breakpoint based on current screen width
      if (clientWidth <= this.onePopup) {
       this.currentBreakpoint = "onePopup"
      } else if (clientWidth <= this.twoPopups) {
       this.currentBreakpoint = "twoPopups"
      } else if (clientWidth >= this.threePopups) {
       this.currentBreakpoint = "threePopups"
      }

      this.totalPopups = this.activityPopups.length
      if(this.currentBreakpoint === 'onePopup' && this.totalPopups >= 1) {
        this.calcVisible(1)
        this.calcHidden()
      }
      else if(this.currentBreakpoint === 'twoPopups' && this.totalPopups >= 1) {
        this.calcVisible(2)
        this.calcHidden()
      }
      else if(this.currentBreakpoint === 'threePopups' && this.totalPopups >= 1) {
        this.calcVisible(3)
        this.calcHidden()
      }
      else {
        this.reset()
      }
      
      if(this.popupsHidden > 0) {
        this.showPopupCounter = true
      }
    },
    calcVisible(num) {
      if(this.totalPopups > num || this.totalPopups == num) {
        this.popupsVisible = num
      }
      else if(this.totalPopups < num && this.totalPopups > 0) {
        this.popupsVisible = this.totalPopups
      }
      else {
        this.popupsVisible = 0
      }
    },
    calcHidden() {
      let tmp = _.clone(this.totalPopups)
      let remain = tmp - this.popupsVisible
      if(remain > 0) {
        this.popupsHidden = remain
      }
      else {
        this.popupsHidden = 0
      }
    },
    reset() {
        this.popupsVisible = 0
        this.popupsHidden = 0
        this.showPopupCounter = false
    }
  }
}