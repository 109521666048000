
import { BButton } from 'bootstrap-vue'
import FeatherIcon from "@/helpers/FeatherIcon"

export default {
  name: 'PugButton',
  components: {
    BButton,
    FeatherIcon
  },
  props: {
    variant: {
      type: String,
      default: 'primary'
    },
    size: {
      type: String,
      default: 'md'
    },
    icon: {
      type: String
    },
    featherIcon: {
      type: String
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    block: {
      type: Boolean,
      default: false
    },
    link: {
      type: Boolean,
      default: false
    },
    label: {
      type: String
    },
  },
  methods: {
    onClick(e) {
      /**
       * @event click
       */
      this.$emit('click', e)
    }
  }
}
