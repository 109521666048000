
export default {
  name: "EmptyState",
  props: {
    // string
    title: {
      type: String,
      required: false
    },
    // string
    subtitle: {
      type: String,
      required: false
    },
    /**
     * image url or path
     */
    imgUrl: {
      type: String,
      required: false
    },
    /**
     * set height of image
     */
    imgHeight: {
      type: String,
      default: "110px"
    },
    buttons: {
      type: Array
    },
    /**
     * toggles border outline of card
     */
    showCard: {
      type: Boolean,
      required: false
    },
    /**
     * toggles outline of card with dashed border
     */
    showCardWithBorder: {
      type: Boolean,
      required: false
    },
    /**
     * set lowest height of card
     */
    cardMinHeight: {
      type: Number,
      required: false,
      default: 560
    }
  },
  render(createElement) {
    var children = []

    if (this.imgUrl) {
      var img = createElement("b-img-lazy", {
        class: {
          "my-3": true
        },
        props: {
          src: this.imgUrl,
          height: this.imgHeight
        }
      })
      children.push(img)
    }

    if (this.title) {
      var elem = createElement(
        "h2",
        {
          class: {
            "mt-2 mb-0 text-wrap text-center": true
          },
          style: {
            'font-size': '24px',
            'line-height': '33px'
          }
        },
        this.title
      )
      children.push(elem)
    }

    if (this.subtitle) {
      var sub = createElement(
        "p",
        {
          class: {
            "my-2 text-wrap text-center": true
          },
          style: {
            color: "#79818c",
            "font-size": "16px",
            "line-height": "19px"
          }
        },
        this.subtitle
      )
      children.push(sub)
    }

    var buttons
    if (this.buttons && this.buttons.length) {
      buttons = []
      for (let butt of this.buttons) {
        buttons.push(createElement(
          "b-button",
          {
            class: butt.class,
            props: {
              variant: butt.variant,
              block: butt.block
            },
            on: {
              click: (e) => {
                this.clickHandler(butt)
              }
            }
          },
          butt.label
        ))
      }
    }

    children.push(
      createElement(
        "div",
        {
          class: {
            "mt-3": !this.title && !this.subtitle ? false : true,
            "w-100": true,
            "d-flex": true,
            "justify-content-center": true,
          }
        },
        buttons && buttons.length ? buttons : this.$slots.default
      )
    )
    var wrapper = createElement(
      "div",
      {
        class: {
          row: true,
          "h-100": true
        }
      },
      [
        createElement(
          "div",
          {
            class: {
              col: true,
              "my-3": true,
              "d-flex": true,
              "flex-column": true,
              "align-items-center": true,
              "justify-content-center": true
            }
          },
          children
        )
      ]
    )

    if (this.showCard === true) {
      return createElement(
        "b-card",
        {
          props: {
            "border-variant": "white",
            "body-class": "d-flex align-items-center justify-content-center"
          },
          class: {
            'empty-state-border': this.showCardWithBorder
          },
          style: {
            "min-height": `${ this.cardMinHeight }px`,


          },

        },
        [wrapper]
      )
    }
    else {
      return wrapper
    }
  },
  methods: {
    clickHandler(button) {
      this.$emit('onClick', button)
    }
  }
}
