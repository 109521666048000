
import { mapMutations, mapGetters } from "vuex"
import _ from "lodash"


export default {
  name: 'PopupCardCounter',
  props: {
    popupsHidden: {
      type: Number,
      required: true,
    },
    totalPopups: {
      type: Number,
      required: true,
    },
    popupsVisible: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      activityPopups: "activityPopup/getActivityPopups",
      activeIndex: "activityPopup/getActivePopupIndex",
    }),
  },
  mounted() {
    // this.getVisiblePopups()
  },
  methods: {
    getRight() {
      let right = 0
      if (this.activeIndex !== -1) {
        right = (this.popupsVisible - 1) * 250 + 30
        right = right + 510
      }
      else if (this.activeIndex === -1) {
        right = this.popupsVisible * 250 + 30
      }
      // right = this.totalPopups * 250 + right
      return right
    },
    getVisiblePopups() {
      let popups = []
      let popReverse = _.cloneDeep(this.activityPopups)
      popReverse = popReverse.reverse()
      for (let i = 0; i < this.totalPopups - this.popupsVisible; i++) {
        popups.push(popReverse[i])
      }
      return popups
    },
    getTippyContent() {
      let popups = this.getVisiblePopups()
      let content = "<div style='text-align: left'>"
      for (let pop of popups) {
        content = content + `${ pop.contact && pop.contact.first_name ? pop.contact.first_name : "" } ${ pop.contact && pop.contact.last_name ? pop.contact.last_name : "" }<br>`
      }
      content += "</div>"
      return content
    }
  },
}
