import { render, staticRenderFns } from "./CardWithImage.vue?vue&type=template&id=65eb709c&lang=pug"
import script from "./CardWithImage.vue?vue&type=script&lang=js"
export * from "./CardWithImage.vue?vue&type=script&lang=js"
import style0 from "./CardWithImage.vue?vue&type=style&index=0&id=65eb709c&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FeatherIcon: require('/helpers/FeatherIcon.vue').default,PugImg: require('/dashboard/pug-img/PugImg.vue').default,PugBadge: require('/dashboard/badges/pug-badge/PugBadge.vue').default,PugButton: require('/dashboard/buttons/pug-button/PugButton.vue').default})
