/**
 * Convert Snake_Case String to title case string.
 *
 * @param { string } value String passed into filter
 *
 * @example
 *      {{ "hello_world" | snakeToTitlecase }}
 *
 *      Output: "Hello World"
 */
export default function snakeToTitlecase(value) {
  if (!value) {
    return ''
  }

  return value
    .split('_')
    .map(function(item) {
      return item.charAt(0).toUpperCase() + item.substring(1)
    })
    .join(' ')
}
